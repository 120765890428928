<template>
    <div class="home">
        <v-card>
            <v-container fluid pt-0 mt-0>
                <v-row class="blue-grey darken-4">
                    <Breadcrumbs />
                </v-row>
                <v-row>
                    <v-col cols="12"></v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="warning" dark v-bind="attrs" v-on="on">{{ selectedDeveloper }}</v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in listDeveloper" :key="index" 
                                    @click="changeDeveloper(item.value)">
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        &nbsp;&nbsp;&nbsp;
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark v-bind="attrs" v-on="on" class="ml-1">{{ selectedProject }}</v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-for="(item, index) in listProject" :key="index" 
                                    @click="changeProject(item.value)">
                                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12"></v-col>
                </v-row>

                <v-col cols="12">
                    <v-data-table dense 
                        :headers="headers" :items="tableData" :search="search" item-key="lock_id"
                        :custom-filter="filterTable" class="elevation-1" :items-per-page="100">
                        
                        <template v-slot:top>
                            <v-text-field v-model="search" label="Search" class="mx-4" ></v-text-field>
                        </template>

                    </v-data-table>
                </v-col>

                <!--
                <div>
                    <v-row>
                        <v-col cols="12"></v-col>
                    </v-row>

                    <v-row>
                        <v-col lg="11" md="11" xs="12" cols="12">
                            <v-text-field class="mr-4 mt-n2" v-model="search" append-icon="mdi-search" label="Search" single-line hide-details></v-text-field>
                        </v-col>
                        <v-col lg="1" md="1" xs="12" cols="12">
                            <download-excel :data="Object.values(newObject)" :fields="csvFields" :name="selectedBroker + '_position_' + selectedBook">
                                <v-btn color="#08591c" class="accent-4">To CSV</v-btn>
                            </download-excel>
                        </v-col>
                        <v-col cols="12">
                            <v-data-table dense :search="search" :headers="headers" :items="Object.values(newObject)" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :custom-sort="customSort"
                                :custom-filter="filterTable" :single-expand="true" :expanded.sync="expanded" item-key="symbol" @click:row="rowClicked" class="elevation-1"
                                :items-per-page="itemsPerPage">

                                <template v-slot:top>
                                    <v-row>
                                        <v-checkbox class="mr-5 ml-5" dense v-model="item.align == 'left'" :label="item.text" v-for="item in headers" :key="item.value" v-on:click="checkHeader(item)">
                                        </v-checkbox>
                                    </v-row>
                                </template>
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">
                                        <v-text-field class="mr-4 mt-n2 mb-5" v-model="searchLogin" append-icon="mdi-search" label="Search Login" single-line hide-details></v-text-field>
                                        <v-data-table dense :search="searchLogin" :headers="loginDetailHeaders" :items="symbolDetailObject" v-if="loadSubTable"
                                            :sort-by="['volume_long', 'volume_short', 'volume_net']" :sort-desc="[false, true]" multi-sort item-key="login" class="elevation-1" :items-per-page="10">
                                        </v-data-table>
                                    </td>
                                </template>

                                <template v-slot:item.symbol="{ item }">
                                    {{ item.symbol }}
                                </template>


                                <template v-slot:item.hg_net="{ item }">
                                    <span>
                                        {{ outputHedge(item, hedgeObject)["net"] }}
                                    </span>
                                </template>

                                <template v-slot:item.hg_float="{ item }">
                                    <span>
                                        {{ outputHedge(item, hedgeObject).netPnl.toFixed(2) }}
                                    </span>
                                </template>

                                <template v-slot:item.pb_long="{ item }">
                                    <span>{{ roundDecimal(item.pbLong, 2) }}</span>
                                </template>
                                <template v-slot:item.pb_short="{ item }">
                                    <span>{{ roundDecimal(item.pbShort, 2) }}</span>
                                </template>
                                <template v-slot:item.netPosition="{ item }">
                                    <v-chip small label v-if="Math.abs(item.netPosition) >= 30" :color="'red'" dark>
                                        {{ roundDecimal(item.netPosition, 2) }}
                                    </v-chip>
                                    <v-chip small label v-if="Math.abs(item.netPosition) < 30" dark>
                                        {{ roundDecimal(item.netPosition, 2) }}
                                    </v-chip small label>
                                </template>
                                <template v-slot:item.floatingPnl="{ item }">
                                    <span>
                                        {{ roundDecimal(item.floatingPnl, 2) }}
                                    </span>
                                </template>
                                <template v-slot:item.floatingPnlMt4="{ item }">
                                    <span>
                                        {{ roundDecimal(item.floatingPnlMt4, 2) }}
                                    </span>
                                </template>
                                <template v-slot:item.closedPnl="{ item }">
                                    <span>
                                        {{ roundDecimal(item.closedPnl, 2) }}
                                    </span>
                                </template>
                                <template v-slot:item.netPnl="{ item }">
                                    <v-chip small label v-if="Math.abs(item.netPnl) >= 20000" :color="'red'" dark>
                                        {{ roundDecimal(item.netPnl, 2) }}
                                    </v-chip>
                                    <v-chip small label v-if="Math.abs(item.netPnl) < 20000" dark>
                                        {{ roundDecimal(item.netPnl, 2) }}
                                    </v-chip small label>
                                </template>
                                <template v-slot:item.avgBuyPrice="{ item }">
                                    {{ getAveragePrice(item.totalSumLong, item.totalVolLong, item.digits) }}
                                </template>
                                <template v-slot:item.avgSellPrice="{ item }">
                                    {{ getAveragePrice(item.totalSumShort, item.totalVolShort, item.digits) }}
                                </template>

                            </v-data-table>
                        </v-col>
                    </v-row>
                </div>
                -->
            </v-container>
        </v-card>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Breadcrumbs from "@components/Breadcrumbs";
// import { socket } from "@components/mixins/socket";
import { helper } from "@components/mixins/helper";
import { alert } from "@components/mixins/alert";
import { position } from "@components/mixins/position";
import IndicatorIcon from "@components/IndicatorIcon";
import * as apiBook from "@components/api/pk/book";
import { io } from "socket.io-client";
import * as apiAlertChannel from "@components/api/pk/alert-channel";
import NotificationSwitch from "@components/ComponentNotificationSwitch";

export default {
    name: "Home",
    components: {
        "indicator-icon": IndicatorIcon, Breadcrumbs,
        NotificationSwitch
    },
    mixins: [helper, alert, position],
    data() {
        return {
            search: "",
            tableData: [
                    
            ],
            headers: [
                { text: "Room Id", value: "room_id" },
                { text: "Lock Id", value: "lock_id" },
                { text: "First Name", value: "first_name" },
                { text: "Last Name", value: "last_name" },
                { text: "Email", value: "email" },
                { text: "Mobile", value: "mobile" },
                { text: "Type", value: "type" },
                { text: "Start Date", value: "start_date" },
                { text: "Battery Remaining", value: "battery_remaining" },
                { text: "Hi Bulter Rego", value: "hi_bulter_rego" },
                { text: "Iht Rego", value: "iht_rego" },
            ],
            listDeveloper: [
                { text: "Poly Mel", value: "Poly Mel"},
                { text: "R&F", value: "R&F"},
                { text: "Centurion", value: "Centurion"},
                { text: "Positive", value: "Positive"},
            ],
            listProject: [
                { text: "WWP", value: "WWP" },
                { text: "LCDT", value: "LCDT" },
                { text: "RDS", value: "RDS" },
                { text: "SCOFLD", value: "SCOFLD" },
                { text: "BKM HILL", value: "BKM HILL" },
                { text: "CastleH", value: "CastleH" },
            ],
            selectedDeveloper: "Poly Mel",
            selectedProject: "WWP"
        };
    },
    watch: {
    },
    computed: {
        ...mapState([
            "tableData1",
            "tableData2",
            "tableData3"
        ])
    },
    methods: {
        changeDeveloper(value) {
            this.selectedDeveloper = value;
            switch (value) {
                case "Poly Mel":
                    this.tableData = this.tableData1;
                    break;
                case "R&F":
                    this.tableData = this.tableData2;
                    break;
                case "Centurion":
                    this.tableData = this.tableData3;
                    break;
                case "Positive":
                    this.tableData = this.tableData2;
                    break;
            }
        },
        changeProject(value) {
            this.selectedProject = value;
            switch (value) {
                case "WWP":
                    this.tableData = this.tableData1;
                    break;
                case "LCDT":
                    this.tableData = this.tableData2;
                    break;
                case "RDS":
                    this.tableData = this.tableData3;
                    break;
                case "SCOFLD":
                    this.tableData = this.tableData2;
                    break;
                case "BKM HILL":
                    this.tableData = this.tableData1;
                    break;
                case "CastleH":
                    this.tableData = this.tableData3;
                    break;
            }
        },
        filterTable(value, search, item) {
            return (
                value != null &&
                search != null &&
                typeof value === "string" &&
                value.toString().indexOf(search) !== -1
            );
        }
    },
    created() {
        this.tableData = this.tableData1;
    },
    async mounted() {
    },
    destroyed() {
        // this.$store.state.socket.removeAllListeners();
    },
};
</script>
