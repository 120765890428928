<template>
    <div>
        <v-switch v-model="disable" :label="`Disable Global Notification ==> ${disable.toString().toUpperCase()}`"></v-switch>
    </div>
</template>
<script>

export default {
    components: {},
    data() {
        return {
            disable: true
        }
    },
    watch: {
        disable(nv, ov) {
            localStorage.setItem("globalNotificationAlert", nv.toString());
        }
    },
    mounted: {

    }
}
</script>