<template>
    <v-icon :color="getIndicatorColor(indicator)" dark right size="medium">{{ getIndicatorIcon(indicator) }}</v-icon>
</template>
<script>
export default {
    name: "indicator-icon",
    props: {
        indicator: 0
    },
    methods: {
        getIndicatorColor(indicator) {
            return indicator == 1 ? "red": (indicator == 0 ? "orange": "green");
        },
        getIndicatorIcon(indicator) {
            return indicator == 1 ? "mdi-arrow-up-bold": (indicator == 0 ? "mdi-minus": "mdi-arrow-down-bold");
        }
    },
    mounted() {
    }
}
</script>